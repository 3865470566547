<template>
  <div class="image-box" v-loading="showLoading">
    <el-form ref="form" :model="postDetail" label-width="160px" label-position="left">
      <div class="input-box">
        <el-form-item label="预览图片">
          <el-upload
              class="upload-demo"
              action="none"
              multiple
              accept="image/*"
              :limit="100"
              :file-list="processedResList"
              :on-exceed="imageCountOut"
              :on-remove="handleRemove"
              :on-change="fileChange"
              :before-upload="beforeUpload"
              :auto-upload="false"
              list-type="picture-card">
            <el-button size="small" type="primary">点击选择</el-button>
            <div slot="tip" class="el-upload__tip">
              一次最多上传50张图片文件，且不超过1MB
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="postDetail.text" class="input"></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-input v-model="postDetail.tag" class="input"></el-input>
        </el-form-item>
        <el-form-item label="发布者OpenID">
          <el-input v-model="postDetail.oid" class="input"></el-input>
        </el-form-item>
      </div>

      <el-form-item class="action-buttons">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
        :title="dialogTitle"
        @close="closeDialog"
        top="5vh"
        :visible.sync="showDialog"
        :close-on-click-modal="false"
        width="800px"
        append-to-body
        :destroy-on-close="true"
    >
      <TemplateDataAdd
          @saveStatus="saveStatus"
          :templateData="templateData"
          :id="postDetail.id"
          :is-title="isTitle"
          :type="postDetail.templateType"
          :is-load-data="isLoadDialog"
          :is-add-image-data="isAddImageData"
          :is-update="isUpdateTemplateData"
      />
    </el-dialog>
  </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_IMAGE_HOST;
export default {
  name: "EmojiImageUpload",
  props: ["postDetail", "categoryList", "isUpdate", "loadData", "imageType"],
  data() {
    return {
      dialogTitle: "",
      showDialog: false,
      showLoading: false,
      postCount: 0,
      templateData: {},
      isTitle: false,
      isUpdateTemplateData: false,
      isAddImageData: false,
      isLoadDialog: false,
      fileList: [],
    };
  },
  computed: {
    processedResList() {
      console.log(this.postDetail);

      if (!this.postDetail.resList || this.postDetail.resList.length === 0) {
        return this.postDetail.resList;
      }
      return this.postDetail.resList.map((item, index) => {
        const url = this.getPostImageUrl(item);
        console.log("测试----",item, url)
        if (typeof url === 'string') {
          console.log("测试---1111",`${item}_${index}`)
          return {name: `${item}_${index}`, url,id: index};
        } else {
          item.url = URL.createObjectURL(item);
          console.log("测试----2222",item.url)
          return item;
        }
      });
    },
  },
  methods: {
    getPostImageUrl(item) {
      console.log(item);
      // 检查 item 是否为字符串
      if (typeof item !== 'string') {
        console.error('item is not a string:', item);
        return item; // 或者你可以返回一个默认值
      }
      // 检查 item 是否以 "http" 开头
      return item.startsWith("http") ? item : `${baseUrl}postImageUrl/${item}`;
    },
    imageCountOut() {
      this.showErrorMessage("只能选择一个文件");
    },
    handleRemove(file, fileList) {
      this.postDetail.resList = this.postDetail.resList.filter(
          (item) => file.name !== item.name
      );
    },
    save() {
      this.isUpdate ? this.updateImage() : this.addImage();
    },
    fileChange(file) {
      if (!this.beforeUpload(file)) return;
      if (this.postDetail.resList === undefined) {
        this.postDetail.resList = []
      }
      console.log("测试---333",file.raw)
      this.postDetail.resList.push(file.raw);
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) this.$message.error("图片大小不能超过 1MB!");
      return isLt1M;
    },
    showErrorMessage(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        type: "error",
        showCancelButton: false,
      });
    },
    addImage() {
      if (!this.validateForm()) return;
      this.uploadFile(this.uploadFileSuccess, this.uploadFileError)
    },
    prepareParams(isUpdate = false) {
      return {
        "tag": this.postDetail.tag || "",
        "text": this.postDetail.text,
        "oid": this.postDetail.oid,
        "checkStatus": 1
      };
    },
    uploadFileSuccess(data) {
      console.log("上传成功后", data);
      const params = this.prepareParams();
      // 将 resList 添加到 params 对象中，并将其转换为字符串
      params.resList = JSON.stringify(data.urls);
      this.uploadData("/post/addEmoji", params, "添加成功");
    },
    uploadFileError() {

    },
    updateImage() {
      // const params = this.prepareParams(true);
      // this.uploadData("/images/updateImage", params, "修改成功");
    },
    validateForm() {
      const {text, tag} = this.postDetail;
      if (!text) return this.showErrorMessage("标题不能为空");
      if (!tag) return this.showErrorMessage("标签不能为空");
      if (!this.postDetail.resList || this.postDetail.resList.length === 0) return this.showErrorMessage("图片不能为空");
      return true;
    },
    logFormData(formData) {
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
    },
    uploadData(url, params, successMessage) {
      console.log(url, params, successMessage);
      this.showLoading = true;
      this.$request
          .post(url, params, {
            // json格式
            headers: {
              "Content-Type": "application/json"
            },
          }, {timeout: 180000})
          .then((response) => {
            this.showLoading = false;
            if (response.status === 200) {
              this.$confirm(successMessage, "提示", {
                confirmButtonText: "确定",
                type: "success",
                showCancelButton: false,
              }).then(() => this.$emit("saveStatus", true));
            } else {
              this.showErrorMessage(response.message);
            }
          })
          .catch(() => {
            this.showLoading = false;
            this.showErrorMessage("网络错误");
          });
    },
    cancel() {
      this.$emit("saveStatus", false);
    },
    closeDialog() {
      this.templateData = {};
      this.isAddImageData = false;
      this.isUpdateTemplateData = false;
      this.isLoadDialog = false;
    },
    saveStatus() {
      this.showDialog = false;
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      this.showLoading = false
    },
    uploadFile(onSuccess, onError) {
      const that = this;
      const params = new FormData()
      // 确保 resList 是一个包含文件对象的数组
      if (Array.isArray(this.postDetail.resList)) {
        this.postDetail.resList.forEach((file, index) => {
          params.append("files", file);
        });
      } else {
        console.error('resList is not an array of files:', this.postDetail.resList);
        return;
      }
      //emoji表情直接上传到帖子路径下
      params.append("path", "postImageUrl/")
      this.logFormData(params)
      console.log("上传", this.postDetail.resList)
      this.$request.post("/file/upload", params)
          .then(function (data) {
            console.log("data = " + JSON.stringify(data))
            that.hideLoading()
            if (data.status === 200) {
              if (onSuccess) {
                onSuccess(data.data)
              }
            } else {
              if (onError) {
                onError()
              }
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'error'
              })
            }
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
  },
};
</script>

<style scoped>
.image-box {
  width: 100%;
}

.input-box {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.action-buttons {
  text-align: center;
}

.image-container {
  display: flex;
  flex-wrap: wrap; /* 自动换行 */
  gap: 10px; /* 图片之间的间距 */
}

.image-item {
  width: 100px; /* 图片的宽度 */
  height: 100px; /* 图片的高度，确保统一大小 */
  object-fit: cover; /* 确保图片不变形 */
  border-radius: 5px; /* 圆角效果，可选 */
}

.input {
  width: 300px;
}

</style>