import VueRouter from "vue-router";

import Login from "../components/Login";
import Index from "../components/Index";
import MainPage from "../components/MainPage";
import ImageManage from "../components/resources/ImageManage";
import ImageCategory from "../components/resources/ImageCategory";
import FontResource from "../components/resources/FontResource";
import ModelResource from "../components/resources/ModelResource";
import TutorialResource from "../components/resources/TutorialResource";
import Feedback from "../components/userData/Feedback";
import AppSetting from "@/components/setting/AppSetting";
import RecycleManage from "@/components/resources/RecycleManage";
import UserShare from "../components/userData/UserShare";
import ExceptionInfo from "../components/setting/ExceptionInfo";
import SearchMatchRules from "../components/setting/SearchMatchRules";
import PostManager from "../components/post/PostManager";
import FileManager from "@/components/file/FileManager.vue";
import AudioPackManager from "@/components/resources/AudioPackManager.vue";
import FunnyPlayManager from "@/components/funnyPlay/FunnyPlayManager.vue";
import FunnyPlayListenerManager from "@/components/funnyPlay/FunnyPlayListenerManager.vue";
import SearchImageManage from "@/components/resources/SearchImageManage.vue";
import PostDetail from "@/components/detail/PostDetail.vue";
import MessageCenterManager from "@/components/resources/MessageCenterManager.vue";
import ComfyStyleManager from "@/components/comfy/ComfyStyleManager.vue";
import AppStatisticsManager from "@/components/ads/AppStatisticsManager.vue";
import AdReportManager from "@/components/ads/AdReportManager.vue";
import VipReportManager from "@/components/vip/VipReportManager.vue";
import EmojiManager from "@/components/post/EmojiManager.vue";

export default new VueRouter({
    mode: process.env.VUE_APP_MODE,
    base: process.env.VUE_APP_PATH,
    APP_PATH: process.env.VUE_APP_PATH,
    routes: [
        {
            path: "/",
            component: Index
        },
        {
            name: "login",
            path: "/login",
            component: Login
        },
        {
            name: "detail",
            path: "/detail",
            component: PostDetail
        },
        {
            name: "index",
            path: "/index",
            component: MainPage,
            children: [
                //图片管理
                {
                    name: "searchImageManage",
                    path: "searchImageManage",
                    component: SearchImageManage,
                },
                //图片管理
                {
                    name: "imageManage",
                    path: "imageManage",
                    component: ImageManage,
                },
                //回收站
                {
                    name: "recycleManage",
                    path: "recycleManage",
                    component: RecycleManage,
                },
                //分类列表
                {
                    name: "imageCategory",
                    path: "imageCategory",
                    component: ImageCategory,
                },
                //字体列表
                {
                    name: "fontResource",
                    path: "fontResource",
                    component: FontResource,
                },
                //语音包列表
                {
                    name: "audioPackManager",
                    path: "audioPackManager",
                    component: AudioPackManager,
                },
                //模型列表
                {
                    name: "modelResource",
                    path: "modelResource",
                    component: ModelResource,
                },
                //教程列表
                {
                    name: "tutorialResource",
                    path: "tutorialResource",
                    component: TutorialResource,
                },
                //用户反馈
                {
                    name: "feedback",
                    path: "feedback",
                    component: Feedback,
                },
                //系统设置
                {
                    name: "appSetting",
                    path: "appSetting",
                    component: AppSetting,
                },
                //用户分享
                {
                    name: "userShare",
                    path: "userShare",
                    component: UserShare,
                },
                //系统异常
                {
                    name: "exceptionInfo",
                    path: "exceptionInfo",
                    component: ExceptionInfo,
                },
                //匹配规则
                {
                    name: "searchMatchRules",
                    path: "searchMatchRules",
                    component: SearchMatchRules,
                },
                //社区发帖管理
                {
                    name: "postManager",
                    path: "postManager",
                    component: PostManager,
                },
                //emoji表情管理
                {
                    name: "emojiManager",
                    path: "emojiManager",
                    component: EmojiManager,
                },
                //文件删除
                {
                    name: "fileManager",
                    path: "fileManager",
                    component: FileManager,
                },
                //趣玩游戏管理
                {
                    name: "funnyPlayListener",
                    path: "funnyPlayListener",
                    component: FunnyPlayListenerManager,
                },
                //趣玩游戏管理
                {
                    name: "funnyPlay",
                    path: "funnyPlay",
                    component: FunnyPlayManager,
                },
                //消息中心
                {
                    name: "messageCenter",
                    path: "messageCenter",
                    component: MessageCenterManager,
                },
                //ComfyUI管理
                {
                    name: "comfyStyleManager",
                    path: "comfyStyleManager",
                    component: ComfyStyleManager,
                },
                //APP统计
                {
                    name: "appStatistics",
                    path: "appStatistics",
                    component: AppStatisticsManager,
                },
                //广告报表
                {
                    name: "adReportManager",
                    path: "adReportManager",
                    component: AdReportManager,
                },
                //广告报表
                {
                    name: "vipReportManager",
                    path: "vipReportManager",
                    component: VipReportManager,
                }
            ]
        },
    ]
})
