<template>
  <div class="emoji_manager" v-loading.fullscreen.lock="showLoading">
    <div class="operation_bar">
      <!--操作列表-->
      <div class="input_box">
        <el-select v-model="pageCount" placeholder="请选择" @change="reset">
          <el-option
              v-for="item in pageNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="input_box">
        <el-input placeholder="请输入内容搜索" v-model="searchKey" class="input-with-select"
                  @change="search"></el-input>
      </div>
      <div class="input_box">
        <el-input placeholder="请输入ID搜索" v-model="searchPostId" class="input-with-select"
                  @change="search"></el-input>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="startTime"
            type="date"
            placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="endTime"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>

    <div class="operation_bar">
      <div class="btn_box">
        <el-button type="warning" @click="search">搜索</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="addEmoji">添加</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="data_box">
      <el-table
          ref="multipleTable"
          class="table"
          :data="postList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @row-click="toggleRowSelection"
          :row-class-name="tableRowClassName"
          @sort-change="handleSortChange">
        <el-table-column
            type="selection"
            width="55"
        >
        </el-table-column>
        <el-table-column
            fixed
            align="center"
            prop="id"
            label="ID"
            width="60"/>
        <el-table-column
            fixed
            align="center"
            prop="tag"
            label="标签"
            width="160"/>
        <el-table-column
            align="center"
            prop="text"
            label="标题"
            width="180">
        </el-table-column>
        <el-table-column
            align="center"
            label="图片"
            width="500">
          <template slot-scope="scope">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <a v-for="(item, index) in scope.row.resList.slice(0, 5)"
                 :key="index"
                 :src="getPostImageUrl(item)"
                 :href="getPostImageUrl(item)"
                 target="_blank">
                <el-image
                    style="width: 100px; height: 100px;"
                    :src="getPostImageUrl(item)">
                </el-image>
              </a>
              <div v-if="scope.row.resList.length > 5" @click="updateEmoji(scope.row)" style="width: 100px; height: 100px; background-color: #f0f0f0; text-align: center; line-height: 100px; cursor: pointer;">
                +{{ scope.row.resList.length - 5 }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            fixed
            align="center"
            prop="pid"
            label="评论的帖子ID"
            width="120"/>
        <el-table-column
            align="center"
            prop="commentN"
            label="评论数"
            width="100">
        </el-table-column>
        <el-table-column
            align="center"
            prop="likeN"
            label="点赞"
            width="100">
        </el-table-column>
        <el-table-column
            label="审核状态"
            align="center"
            width="100">
          <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.isChecked==0?'':scope.row.isChecked>=1 ? 'success':'danger'">
                {{ scope.row.isChecked == 0 ? '未审核' : (scope.row.isChecked >= 1 ? '审核通过' : '审核不通过') }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="发布时间"
            align="center"
            prop="t"
            width="180">
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium"
                       @click="updateEmoji(scope.row)">查看
            </el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete"
                       @click="deleteEmoji(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="10%"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="80%"
               @opened="dialogOpened"
               :destroy-on-close="true"
               :fullscreen="false">
      <EmojiImageUpload v-on:saveStatus="handleSaveStatus" :postDetail="selectedPostInfo"
                        :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"/>
    </el-dialog>
  </div>
</template>

<script>
import EmojiImageUpload from "@/components/post/EmojiImageUpload.vue";

const baseUrl = process.env.VUE_APP_IMAGE_HOST;
export default {
  name: "EmojiManager",
  components: {
    EmojiImageUpload,
  },
  data() {
    return {
      dialogTitle: "",
      isUpdate: false,
      selectedPostInfo: {
      },
      requestNum: 0,
      showLoading: false,
      showDialog: false,
      postList: [],
      pageNumList: [
        { label: '每页20条', value: 20 },
        { label: '每页50条', value: 50 },
        { label: '每页100条', value: 100 },
        { label: '每页200条', value: 200 },
        { label: '每页500条', value: 500 }
      ],
      categoryList: [
        { label: '全部', value: "all" },
        { label: '审核通过', value: "1" },
        { label: '未审核', value: "0" },
        { label: '审核不通过', value: "-1" }
      ],
      hotOrderType: 0,
      timeOrderType: 0,
      updateTimeOrderType: 0,
      hasTemplateDataOrderType: 0,
      searchKey: "",
      searchPostId: "",
      postCategory: "all",
      pageNo: 1,
      pageSum: 1,
      reviewStatus:1,
      pageCount: 200,
      startTime: '',
      endTime: "",
      delImageIds: [],
      loadDialogData: false,
      imageBaseUrl: baseUrl,
    };
  },
  methods: {
    getPostImageUrl(item) {
      if (!item) {
        return '';
      }
      return item.startsWith("http") ? item : `${baseUrl}postImageUrl/${item}`;
    },
    fetchPostList() {
      this.showLoading = true;
      this.requestNum++;
      const params = {
        "reviewStatus": this.reviewStatus,
        "pageNo": this.pageNo,
        "pageCount": this.pageCount,
        "searchKey": this.searchKey,
        "postId": this.searchPostId,
        "postCategory": "5"
      };
      console.log("请求结果返回 true =" ,params);
      this.$request.post("/post/queryAllPostList", params)
          .then(data => {
            console.log("请求结果返回 true =" , data.data);
            this.postList = data.data.records;
            this.pageSum = data.data.pages;
            this.requestNum--;
            this.hideLoading();
          })
          .catch(err => {
            console.log("请求结果返回 false =" + err.message);
            this.requestNum--;
            this.hideLoading();
            this.$confirm("网络错误", "提示", {
              confirmButtonText: '确定',
              showClose: false,
              showCancelButton: false,
              center: true,
              type: 'error'
            });
          });
    },
    hideLoading() {
      if (this.requestNum <= 0) {
        this.showLoading = false;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      return rowIndex % 2 === 0 ? 'warning-row' : 'success-row';
    },
    handleSortChange({ column, prop, order }) {
      switch (prop) {
        case "heat":
          this.heatSortChange(order);
          break;
        case "time":
          this.timeSortChange(order);
          break;
        case "updateTime":
          this.updateTimeSortChange(order);
          break;
        case "hasTemplateData":
          this.hasTemplateDataSortChange(order);
          break;
      }
      this.pageNo = 1;
      this.fetchPostList();
    },
    heatSortChange(order) {
      this.hotOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
    },
    timeSortChange(order) {
      this.timeOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
    },
    updateTimeSortChange(order) {
      this.updateTimeOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
    },
    hasTemplateDataSortChange(order) {
      this.hasTemplateDataOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
    },
    reset() {
      this.searchKey = "";
      this.searchPostId = "";
      this.postCategory = "all";
      this.pageNo = 1;
      this.fetchPostList();
    },
    addEmoji() {
      this.selectedPostInfo={
        "oid":"406003371F295363779B3657EE5132BA",
      }
      this.dialogTitle = "添加Emoji";
      this.isUpdate = false;
      this.showDialog = true;
    },
    search() {
      this.fetchPostList();
    },
    handlePageChange(pageNo) {
      this.pageNo = pageNo;
      this.fetchPostList();
    },
    handleSelectionChange(data) {
      this.delImageIds = data.map(item => item.id);
    },
    toggleRowSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    updateEmoji(postInfo) {
      this.dialogTitle = "帖子详情";
      this.isUpdate = true;
      this.showDialog = true;
      this.selectedPostInfo = postInfo;
    },
    handleSaveStatus(flag) {
      if (flag) {
        this.fetchPostList();
      }
      this.showDialog = false;
    },
    closeDialog() {
      this.selectedPostInfo = {};
      this.loadDialogData = false;
    },
    deleteEmoji(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.del(id);
      }).catch(() => {});
    },
    del(id) {
      // Implement delete logic here
    },
    dialogOpened() {
      this.loadDialogData = true;
    },
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "3-1-1");
    this.fetchPostList();
  },
};
</script>

<style scoped>
.emoji_manager {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.operation_bar {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.data_box {
  width: 100%;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.pagination {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  height: 100%;
}
</style>