<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <div class="input_box">
        <el-select v-model="postCategory" placeholder="请选择" @change="queryAllPostList">
          <el-option
              v-for="item in postCategoryList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="input_box">

        <el-select v-model="reviewStatus" placeholder="请选择" @change="queryAllPostList">
          <el-option
              v-for="item in categoryList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!--            操作列表-->
      <div class="input_box">
        <el-select v-model="pageCount" placeholder="请选择" @change="reset">
          <el-option
              v-for="item in pageNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!--            操作列表-->
      <div class="input_box">
        <el-input placeholder="请输入内容搜索" v-model="searchKey" class="input-with-select"
                  @change="search"></el-input>
      </div>
      <div class="input_box">
        <el-input placeholder="请输入ID搜索" v-model="searchPostId" class="input-with-select"
                  @change="search"></el-input>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="startTime"
            type="date"
            placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="endTime"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>

    <div class="box_operation">
      <!--      <div class="btn_box">-->
      <!--        <el-button  type="primary" @click="open">点击查询</el-button>-->
      <!--      </div>-->
      <div class="btn_box">
        <el-button type="warning" @click="search">搜索</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reset">重置</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="queryAllPostList">刷新</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reviewAllPost(1)">批量审核通过({{ delImageIds.length }})</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reviewAllPost(-1)">批量审核不通过({{ delImageIds.length }})</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="delImages">批量删除({{ delImageIds.length }})</el-button>
      </div>
    </div>
    <div class="data_box">
      <el-table
          ref="multipleTable"
          class="table"
          :data="postList"
          style="width: 100%"
          @selection-change="selectAllChange"
          @row-click="toggleSelection"
          :row-class-name="tableRowClassName"
          @sort-change="sortChange">
        <el-table-column
            type="selection"
            width="55"
        >
        </el-table-column>
        <el-table-column
            fixed
            align="center"
            prop="id"
            label="id"
            width="60"/>
        <el-table-column
            fixed
            align="center"
            prop="pid"
            label="评论的帖子ID"
            width="120"/>
        <el-table-column
            align="center"
            prop="text"
            label="内容"
            width="180">
        </el-table-column>

        <el-table-column
            align="center"
            label="图片">
          <template slot-scope="scope">
            <div style="display: grid; grid-template-columns: repeat(auto-fill, 100px); gap: 10px;">
              <a v-for="item in scope.row.resList"
                 :key="item"
                 :href="item.indexOf('http') == 0 ? item : imageBaseUrl + 'postImageUrl/' + item"
                 target="_blank">
                <el-image
                    style="width: 100px; height: 100px;"
                    :src="item.indexOf('http') == 0 ? item : imageBaseUrl + 'postImageUrl/' + item">
                </el-image>
              </a>
            </div>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            prop="commentN"
            label="评论数"
            width="100">
        </el-table-column>

        <el-table-column
            align="center"
            prop="likeN"
            label="点赞"
            width="100">
        </el-table-column>

        <el-table-column
            label="审核状态"
            align="center"
            width="100">
          <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.isChecked==0?'':scope.row.isChecked>=1 ? 'success':'danger'">
                {{ scope.row.isChecked == 0 ? '未审核' : (scope.row.isChecked >= 1 ? '审核通过' : '审核不通过') }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="发布时间"
            align="center"
            prop="t"
            width="180">
        </el-table-column>

        <el-table-column
            width="160"
            align="center"
            label="是否同步到图片"
            v-if="showSyncPostToImage">
          <template slot-scope="scope">
            <el-button type="primary" size="medium"
                       @click="syncPostToImage(scope.row)">同步
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
            width="160"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium"
                       @click="updateImage(scope.row)">审核
            </el-button>

            <el-button type="danger" size="medium" icon="el-icon-delete"
                       @click="delImage(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="1200px"
               @opened="dialogOpen"
               :destroy-on-close="true"
               :fullscreen="false">
      <PostAudit v-on:saveStatus="saveStatus" :postDetail="selectPostInfo"
                 :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"/>
    </el-dialog>
  </div>
</template>

<script>
import PostAudit from "@/components/post/PostAudit";
import ImageBox from "@/components/resources/ImageBox";

const baseUrl = process.env.VUE_APP_IMAGE_HOST;

export default {
  name: "PostManager",
  components: {
    PostAudit,
    ImageBox
  },
  checkStatusItems: [
    {type: '', lable: '未审核'},
    {type: 'success', lable: '审核通过'},
    {type: 'danger', lable: '审核不通过'}
  ],
  data: function () {
    console.log("rotuer.base =" + baseUrl)
    return {
      dialogTitle: "",//弹框标题
      isUpdate: false,//是否时修改图片
      selectPostInfo: {},//当前是修改图片信息时可用
      requestNum: 0,//当前请求数量
      showLoading: false,//是否显示刷新界面
      showDialog: false,//是否显示弹框
      showDialogtoo: false,
      hasLoadImageBox: false,//是否开始加载数据
      showSyncPostToImage: false,
      postList: [],//帖子列表
      pageNumList: [{
        id: 0,
        label: '每页20条',
        value: 20,
      }, {
        id: 1,
        label: '每页50条',
        value: 50
      }, {
        id: 2,
        label: '每页100条',
        value: 100
      }, {
        id: 3,
        label: '每页200条',
        value: 200
      }, {
        id: 4,
        label: '每页500条',
        value: 500
      }],
      postCategoryList: [{
        id: 0,
        label: '全部',
        value: "all",
      }, {
        id: 1,
        label: '帖子',
        value: "0"
      }, {
        id: 2,
        label: '评论',
        value: "1"
      }, {
        id: 3,
        label: '用户投稿，仅供查看无需审核',
        value: "2"
      }, {
        id: 3,
        label: '自动模板，网页端仅供查看无需审核，客户端审核',
        value: "3"
      }, {
        id: 4,
        label: '用户基于自动模板制作的作品或模板',
        value: "4"
      }],//图片分类列表
      categoryList: [{
        id: 0,
        label: '全部',
        value: "all",
      }, {
        id: 1,
        label: '审核通过',
        value: "1"
      }, {
        id: 2,
        label: '未审核',
        value: "0"
      }, {
        id: 3,
        label: '审核不通过',
        value: "-1"
      }],//图片分类列表
      hotOrderType: 0,//热度排序规则 0:不排序 1:升序 2:降序
      timeOrderType: 0,//上传时间排序规则 0:不排序 1:升序 2:降序
      updateTimeOrderType: 0,//更新时间排序规则 0:不排序 1:升序 2:降序
      hasTemplateDataOrderType: 0,//是否有操作数据排序 0:不排序 1:升序 2:降序
      searchKey: "",//图片名称搜索关键字
      searchPostId: "",//搜索关键字
      postCategory: "all",//图片名称搜索关键字
      pageNo: 1,//当前页面
      pageSum: 1,//总页码
      pageCount: 200,//每页显示数据量
      startTime: '',
      endTime: "",
      delImageIds: [],
      loadDialogData: false,
      selectcategory: [],
      reviewStatus: "all",//审核状态
      imageBaseUrl: baseUrl,//审核状态
    }
  },
  methods: {
    /**
     * 获取当前所有帖子列表
     * */
    queryAllPostList: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {
        "reviewStatus": this.reviewStatus,
        "pageNo": this.pageNo,
        "pageCount": this.pageCount,
        "searchKey": this.searchKey,
        "postId": this.searchPostId,
        "postCategory": this.postCategory
      }
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.showSyncPostToImage = this.postCategory === "3";
      this.$request.post("/post/queryAllPostList", params)
          .then(function (data) {
            that.postList = data.data.records
            that.pageSum = data.data.pages
            that.requestNum--;
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      if (this.requestNum <= 0) {
        this.showLoading = false
      }
    },
    /**
     * 分行设置类名
     * */
    tableRowClassName: function ({row, rowIndex}) {
      // if (rowIndex % 2 === 0) {
      //     return 'warning-row';
      // } else if (rowIndex % 2 !== 0) {
      //     return 'success-row';
      // }
      return '';
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      switch (prop) {
        case "heat":
          this.heatSortChange(order)
          break
        case "time":
          this.timeSortChange(order)
          break
        case "updateTime":
          this.updateTimeSortChange(order)
          break
        case "hasTemplateData":
          this.hasTemplateDataSortChange(order)
          break
      }
      this.pageNo = 1;//重置页码
      this.queryAllPostList()
    },
    /**
     * 热度排序规则改变
     * @param order //当前排序规则
     */
    heatSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.hotOrderType = 1;
          break;
        case "descending":
          //降序
          this.hotOrderType = 2;
          break;
        case null:
          //不做操作
          this.hotOrderType = 0;
          break;
      }
    },
    /**
     * 根据时间排序
     * @param order 排序规则
     */
    timeSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.timeOrderType = 1;
          break;
        case "descending":
          //升序
          this.timeOrderType = 2;
          break;
        case null:
          //升序
          this.timeOrderType = 0;
          break;
      }
    },
    /**
     * 根据更新时间排序
     * @param order 排序规则
     */

    hasTemplateDataSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.hasTemplateDataOrderType = 1;
          break;
        case "descending":
          //升序
          this.hasTemplateDataOrderType = 2;
          break;
        case null:
          //升序
          this.hasTemplateDataOrderType = 0;
          break;
      }
    },

    /**
     * 根据是否有操作数据排序
     * @param order 排序规则
     */
    updateTimeSortChange: function (order) {
      switch (order) {
        case "ascending":
          //升序
          this.updateTimeOrderType = 1;
          break;
        case "descending":
          //升序
          this.updateTimeOrderType = 2;
          break;
        case null:
          //升序
          this.updateTimeOrderType = 0;
          break;
      }
    },
    /**
     * 重置界面，情况所有搜索数据
     */
    reset: function () {
      // this.orderType = 0;
      this.searchKey = "";
      this.searchPostId = "";
      this.postCategory = "all";
      this.pageNo = 1;
      this.categoryId = 0;
      this.queryAllPostList()
    },
    /**
     * 搜索功能
     * 清空当前分页情况
     */
    search: function () {
      this.queryAllPostList()
    },
    /**
     * 页码改变时
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.queryAllPostList();
    },

    /**
     * 选项选择时
     * @param data
     */
    selectChange: function (data) {
      if (data.length > 0) {
        this.categoryId = data[data.length - 1]
      } else {
        this.categoryId = 0;
      }
    },
    /**
     * 更新图片信息
     * */
    updateAuditStatus: function (selectPostInfo) {
      this.dialogTitle = "修改图片信息"
      this.isUpdate = true
      this.showDialog = true

      selectPostInfo.categoryIds = [selectPostInfo.firstCategory.id, selectPostInfo.secondCategory.id,
        selectPostInfo.thirdCategory.id]

      selectPostInfo.fileList = [{name: selectPostInfo.tag, url: selectPostInfo.url}]
      if (selectPostInfo.materialImage !== null) {
        selectPostInfo.materialList = [{name: selectPostInfo.tag + "预览", url: selectPostInfo.materialImage}]
      } else {
        selectPostInfo.materialList = []
      }

      this.selectPostInfo = selectPostInfo
    },
    showSyncToImage: function () {

    },
    /**
     * 更新图片信息
     * */
    syncPostToImage: function (selectPostInfo) {
      this.$confirm('此操作会将图片同步到图片列表, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.syncPostToImageServer(selectPostInfo)
      }).catch(() => {
      });
    },
    syncPostToImageServer: function (selectPostInfo) {
      this.showLoading = true;
      const that = this;
      const params = {
        "postId": selectPostInfo.id,
        "templateId": selectPostInfo.templateId
      }
      console.log(params)
      this.$request.post("/post/syncTemplateToImage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("同步成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
                that.queryAllPostList()
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误," + err, "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 更新图片信息
     * */
    updateImage: function (selectPostInfo) {
      this.dialogTitle = "帖子详情"
      this.isUpdate = true
      this.showDialog = true
      this.selectPostInfo = selectPostInfo
    },
    /**
     * 图片保存状态
     */
    saveStatus: function (flag) {
      if (flag) {
        this.queryAllPostList()
      }
      this.showDialog = false
    },

    /**
     * 弹框关闭时
     */

    closeDialog: function () {
      this.selectPostInfo = {}
      this.loadDialogData = false;
    },
    /**
     * 删除某个图片
     * @param id 图片id
     */
    delImage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 单行点击，选中或者不选中
     */
    toggleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /**
     * 多选回调
     * @param data 选中的对象数据
     */
    selectAllChange: function (data) {
      //清空数组
      this.delImageIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delImageIds.push(item.id)
      })
    },
    /**
     * 发出删除一个图片请求
     * @param id
     */
    del: function (id) {

    },
    changePostAuditStatus: function (reviewStatus) {
      this.showLoading = true;
      const that = this;
      const params = {
        "reviewStatus": reviewStatus,
        "ids": this.delImageIds
      }
      this.$request.put("/post/updatePostAuditStatus", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
                that.queryAllPostList()
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 批量评审通过
     * */
    reviewAllPost: function (reviewStatus) {
      this.$confirm(reviewStatus == 1 ? '批量审核通过, 是否继续?' : '批量审核不通过, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.changePostAuditStatus(reviewStatus)
      }).catch(() => {
      });
    },
    /**
     * 删除多个图片
     */
    delImages: function () {
      this.$confirm('删除功能暂不支持', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
      }).then(() => {
      }).catch(() => {
      });
    },
    /**
     * 删除选中的数据
     */
    delSelected: function () {
      if (this.delImageIds === undefined || this.delImageIds.length === 0) {
        return
      }
      // this.showLoading = true;
      // const that = this;
      // const params = new URLSearchParams()
      // this.delImageIds.forEach((item) => {
      //     params.append("ids", item)
      // })
      // this.$request.post("/post/updatePostAuditStatus", params)
      //     .then(function (data) {
      //         that.showLoading = false;
      //         if (data.status === 200) {
      //             that.$confirm("删除成功", "提示", {
      //                 confirmButtonText: '确定',
      //                 showClose: false,
      //                 showCancelButton: false,
      //                 center: true,
      //                 type: 'success'
      //             }).then(function () {
      //                 that.queryAllPostList()
      //             })
      //         } else {
      //             that.showError(data.data)
      //         }
      //     }).catch(function (err) {
      //     that.showLoading = false;
      //     that.hideLoading()
      //     that.showError("网络错误")
      // })
    },
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    dialogOpen: function () {
      this.loadDialogData = true;
    },
    open: function () {
      this.showDialogtoo = true
    },
    postFrom: function (data) {
      console.log(data)
    },
    getCategory: function (name, categorys) {
      categorys.forEach(item => {
        let category = {}
        category.id = item.id
        category.categoryName = name + item.categoryName
        this.categoryList.push(category)
        if (item.childList !== null && item.childList !== undefined && item.childList.length > 0) {
          this.getCategory(category.categoryName + "/", item.childList)
        }
      })
    },
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "3-1-1");
    this.queryAllPostList()
  },

}
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 120px;
  height: 120px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

</style>
